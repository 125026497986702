<template>
    <div>
        <div class="mainnavOuter" v-if="this.windowWidth > 819">
            <nav class="mainnavInner">
                <router-link v-for="(route, index) in theRoutes" :key="index" :to="{ name: route.name }">{{
                    route && route.components && route.components.default && route.components.default.display ?
                        route.components.default.display : route.meta.display }}</router-link>
            </nav>
        </div>
        <div class="mainnav compressedicons" v-else>
            <div @click="toggleMenu()" v-if="!isMenuOpen">
                <div class="icons">
                    <div class="material-icons">menu</div>
                </div>
            </div>
            <div @click="toggleMenu()" v-else>
                <nav class="mainnavCompressed">
                    <router-link v-for="(route, index) in theRoutes" :key="index" :to="{ name: route.name }">{{
                        route && route.components && route.components.default && route.components.default.display ?
                            route.components.default.display : route.meta.display }}</router-link>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { Slide } from 'vue-burger-menu'  // import the CSS transitions you wish to use, in this case we are using `Slide`
import router from "../router"

let theRoutes = router.getRoutes();
theRoutes = theRoutes.filter((route) => route && route.meta && !route.meta.hideNavbar);

export default {
    components: {
        Slide // Register your component
    },
    data() {
        return {
            theRoutes: theRoutes,
            isMenuOpen: false,
            windowWidth: window.innerWidth,
        }
    },
    methods: {
        redirect() {
            this.$router.push({ name: "Home" })
        },
        back() {
            this.$router.go(-1)
        },
        forward() {
            this.$router.go(1)
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            console.log(`toggleMenu ${this.isMenuOpen}`)
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
}
</script>

<style>
/*.mainTitle {
    padding-top: 20px;
    font-family: "Alex Brush", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 4em; 
}*/

.mainnavOuter {
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    /*
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 15px;


    padding: 30px;
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 15px;
    */
}

.mainnavInner {
    display: flex;
    justify-content: space-evenly;
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 1.5em; /*15px;*/
    padding-left: 30px;
    /*
    padding-bottom: 5px;

    padding: 30px;
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 15px;
    */
}

.mainnavInner a {
    padding: 10px;
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    border-radius: 4px;
}

.mainnavInner a.router-link-exact-active {
    color: white;
    background: #32370e;
}

.compressedicons {
    text-align:right;
}

.mainnavCompressed {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-size: 1.5em; /*15px;*/
    /*padding-left: 30px;*/

    display: grid;
    justify-content: space-between;
    align-items: center;

    width: 300px;
    background: grey;
    padding: 5px;

    float: right;
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 60%;
    height: 70%;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mainnavCompressed a {
    font-weight: bold;
    color: #2c3e50;
    text-decoration: none;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mainnavCompressed a.router-link-exact-active {
    color: white;
    background: #32370e;
}

/* material icons setup */
.material-icons {
    font-size: 48px;
    margin-left: 10px;
    color: #2c3e50;
    cursor: pointer;
}

.material-icons:hover {
    color: #777
}
</style>