<template>
    <div class="home">
        <div class="homeTop">
            <img class="homeTop" src="../assets/Food in Capsule - iStock-cropped.png" />
            <div class="messageCenter">
                <h2>Integrative Nutrition</h2>
                <p>Where Sciece Meets Self-Care</p>
            </div>
        </div>
        <hr class="solid">
        <div class="homeMissionOuter">
            <h1>The Mission</h1>

            <div class="homeMission">
                <div>
                    <p>At Kimberly Hornberg Integrative Nutrition, we bring together science and self-care to help you
                        create deeper connections with yourself through personalized, high-quality nutrition, fitness,
                        and
                        lifestyle strategies. Kim’s approach acknowledges the interconnectedness of the body, focusing
                        on
                        finding the root causes of health issues rather than just treating symptoms. With a Master’s
                        degree
                        in Nutrition and Integrative Health, Kim combines her expertise with a passion for helping
                        clients
                        improve their health through balanced nutrition, physical fitness, quality sleep, and deeper
                        connections.</p>
                    <p>As a CNS candidate working under a BCNS supervisor, Kim offers affordable, flexible services
                        tailored
                        to your needs—without the pressure of long-term commitments. Whether you're looking for
                        nutrition
                        guidance, fitness support, or ways to manage stress, each session is designed to empower you
                        with
                        the tools and knowledge to take control of your wellness and create lasting change.</p>
                </div>
                <img id="homeMissionImg" :src="randomHeadshot1" />
            </div>
        </div>
        <hr class="solid">
        <div class="homeServicesOuter">
            <h1>Services</h1>
            <div class="homeServices">
                <img id="homeServicesImg" :src="randomServiceShot" />
                <div>
                    <div class="homeServiceInner">
                        <div class="homeServicesPara">
                            <p>Each session is designed to help you build sustainable habits that enhance not only your
                                physical health but also your mental and emotional well-being. Kim’s holistic,
                                whole-body approach looks beyond symptoms to uncover the root causes of both health
                                issues and behaviors, helping you establish lasting habits that support your goals. With
                                personalized care and clear, actionable steps, she’s here to guide you through every
                                stage of your wellness journey.</p>
                        </div>
                        <div>
                            <div class="servicesBlock">
                                <div>
                                    <div class="serviceBlockElement">Nutrition & Meal Planning</div>
                                    <div class="serviceBlockElement">Mental Health & Self-Care</div>
                                    <div class="serviceBlockElement">Physical Fitness</div>
                                </div>
                                <div>
                                    <div class="serviceBlockElement">Drug-Nutrient Interactions</div>
                                    <div class="serviceBlockElement">Supplementation & Nutrient Deficiencies</div>
                                    <div class="serviceBlockElement">Health Education & Advocacy</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="solid">
        <div class="homeBlogOuter">
            <h1>Blog</h1>
            <div class="homeBlog">
                <div>
                    <h2>Life on the farm, Marathon Training & more</h2>
                    <p>In addition to her integrative nutrition practice, Kim is also an aspiring gardener. Together
                        with her partner, they’re rewilding a small country property in the heart of the Pacific
                        Northwest, transforming it into a kitchen and medicinal garden for friends, family, neighbors,
                        and hopefully one day, the larger community. With rescued animals and a constant stream of DIY
                        projects, their 1.5-acre home has become one of her true passion projects. Kim believes that
                        connecting with the food we grow and the environment it comes from helps us deepen our
                        connection with ourselves, our health, and our purpose. Want to follow along? Check out her blog
                        and upcoming YouTube channel for both integrative nutrition insights, marathon training, and
                        updates on their small farm life adventure.</p>
                    <br /><br />
                    <div style="text-align:center;"><a
                            href="https://substack.com/@kimberlyhornberg?utm_source=user-menu"><Button
                                class="bookNow">Visit my blog here!</Button></a></div>
                    <br /><br />

                </div>
                <img id="homeBlogImg" :src="randomHeadshot2" />
            </div>
        </div>
        <hr class="solid">
        <div class="homeFormsOuter">
            <div class="homeFormsInner">
                <!--<img id="homeFormsImg" :src="randomHeadshot3" />-->
                <div>
                    <div>
                        <div class="homeForm">
                            <div class="homeFormOuter">
                                <hr class="dotted">
                                <h1>Newsletter</h1>
                                <div class="homeFormInner">
                                    <div class="homeFormContent">
                                        <p>Be the first to know about new integrative health research,
                                            tools, offerings, and blog postings.</p>
                                    </div>
                                    <div class="homeFormForm">
                                        <form @submit.prevent="newsletterForm">
                                            <input v-model="newsletterEmail" id="newsletterForm" type="email"
                                                placeholder="Email Address" />
                                            <button>Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="homeForm">
                            <div class="homeFormOuter">
                                <hr class="dotted">
                                <h1>Inquiries</h1>
                                <div class="homeFormInner">
                                    <div class="homeFormContent">
                                        <p>Interested in a 15-minute complementary discovery call, collaboration, or
                                            just
                                            want to connect? Complete the following form & we’ll reach out as soon as
                                            possible.
                                            Looking forward to connecting!</p>
                                    </div>
                                    <div class="homeFormForm">
                                        <form @submit.prevent="contactForm">
                                            <div class="contactOuter">
                                                <div>
                                                    <div class="contactInner">
                                                        <input type="text" placeholder="First Name" />
                                                        <input type="text" placeholder="Last Name" />
                                                    </div>
                                                    <div class="contactInner">
                                                        <input type="email" placeholder="Email Address" />
                                                        <input type="phone" placeholder="Phone" />
                                                    </div>
                                                    <div>
                                                        <textarea placeholder="Message"></textarea>
                                                    </div>
                                                    <button>Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import headShot1 from '../assets/headshot1.png'
import headShot2 from '../assets/headshot2.png'
import headShot3 from '../assets/headshot3.png'
import serviceShot1 from '../assets/Services ImageA.png'
import serviceShot2 from '../assets/Services ImageB.png'
import FormData from 'form-data'
import { Buffer } from 'buffer'

function nextShot(val, list) {
    let retVal = val;
    if (val == list.length - 1) {
        retVal = 0
    } else {
        retVal++
    }
    return retVal
}

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            headShotTop: 0,
            randomHeadshot1: '',
            randomHeadshot2: '',
            randomHeadshot3: '',
            servicesShotTop: 0,
            randomServiceShot: '',
            headShots: [
                headShot1,
                headShot2,
                headShot3,
            ],
            servicesShots: [
                serviceShot1,
                serviceShot2,
            ],
            newsletterEmail: '',

        }
    },
    methods: {

        newsletterForm() {
            //do newsletter form email here
            //To Requester
            const newsletterToClient = new FormData();
            newsletterToClient.append('from', 'agbert@gmail.com');
            newsletterToClient.append('to[0]', this.newsletterEmail);
            newsletterToClient.append('subject', "KimberlyHornberg.com - Thank you for your newsletter subscription!");
            newsletterToClient.append('html', 'Thank you for your request! Kimberly will add you to the subscription list!');
            let auth = Buffer.from('api:a3c3399e7c1a3f926eeb645dd76ed46a-c02fd0ba-154c456f').toString('base64')

            fetch("https://api.mailgun.net/v3/contact.kimberlyhornberg.com/messages", {
                method: 'POST',
                headers: { 'Authentication': 'basic ' + auth },
                body: newsletterToClient,
            })
                .then((response) => response.json())
                .then((clientSend) => {
                    console.log(`Client send result: ${JSON.stringify(clientSend)}`)
                    const newsletterToOwner = new FormData();
                    newsletterToOwner.append('from', 'agbert@gmail.com');
                    newsletterToOwner.append('to[0]', 'agbert@gmail.com');
                    newsletterToOwner.append('subject', "KimberlyHornberg.com - NEW Newsletter Subscription");
                    newsletterToOwner.append('text', `New newsletter client: ${this.newsletterEmail}`);
                    fetch("https://api.mailgun.net/v3/contact.kimberlyhornberg.com/messages", {
                        method: 'POST',
                        headers: { 'Authentication': 'basic ' + auth },
                        body: newsletterToClient,
                    })
                        .then((response) => response.json())
                        .then((ownerSend) => {
                            console.log(`Owner send result: ${JSON.stringify(ownerSend)}`)
                            this.$router.push(`/newsletterThankYou`)
                        }).catch((err) => {
                            console.log(`Error sending to owner: ${err}`)
                        })

                }).catch((err) => {
                    console.log(`Error sending to client: ${err}`)
                })
        },
    },
    mounted() {
        let self = this
        self.headShotTop = Math.floor(Math.random() * self.headShots.length)
        let next1 = nextShot(self.headShotTop, self.headShots)
        let next2 = nextShot(next1, self.headShots)
        self.randomHeadshot1 = self.headShots[self.headShotTop]
        self.randomHeadshot2 = self.headShots[next1]
        self.randomHeadshot3 = self.headShots[next2]
        self.servicesShotTop = Math.floor(Math.random() * self.servicesShots.length)
        self.randomServiceShot = self.servicesShots[self.servicesShotTop]
        console.log(self.randomHeadshot1)
        console.log(self.randomHeadshot2)
        console.log(self.randomHeadshot3)
        console.log(self.randomServiceShot)


        setInterval(function () {
            self.headShotTop = Math.floor(Math.random() * self.headShots.length)
            let next1 = nextShot(self.headShotTop, self.headShots)
            let next2 = nextShot(next1, self.headShots)
            self.randomHeadshot1 = self.headShots[self.headShotTop]
            self.randomHeadshot2 = self.headShots[next1]
            self.randomHeadshot3 = self.headShots[next2]
            self.servicesShotTop = Math.floor(Math.random() * self.servicesShots.length)
            self.randomServiceShot = self.servicesShots[self.servicesShotTop]

            console.log(self.randomHeadshot1)
            console.log(self.randomHeadshot2)
            console.log(self.randomHeadshot3)
            console.log(self.randomServiceShot)
        }, 120000);
    },
}
</script>

<style>
hr.solid {
    border-top: 3px solid #bbb;
}

hr.dotted {
    border-top: 3px dotted #bbb;
}

.homeTop {
    font-size: 1em;
    margin: 5px;
    position: relative;
    text-align: center;
    color: white;
}

.messageCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-text-stroke: 0.5px black;
}

.messageCenter h2 {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-size: calc(4.5vw);
    /*2.5em; calc(1vw + 1vh);*/
    text-shadow: #3c3c3c 2px 2px 20px;
    font-style: bold;
    font-weight: 500;
}

.messageCenter p {
    font-family: "Alegreya SC", sans-serif, Helvetica, Arial;
    font-size: calc(3.5vw);
    /*1em;*/
    /*text-transform: uppercase;*/
    text-shadow: #3c3c3c 2px 2px 20px;
    font-style: bold;
    font-weight: 400;
}

.homeTop img {
    width: 100%;
    object-fit: cover;

}

.logo {
    width: 120px
}

.homeMissionOuter {
    margin: 20px;
}

.homeMission {
    display: flex;
    justify-content: space-evenly;
    /*    margin: 20px;*/
}

.homeMission p {
    margin: 30px;
}

#homeMissionImg {
    width: 20%;
    height: 20%;
}

.homeServices {
    display: flex;
    justify-content: space-evenly;
    margin: 20px;
}

.homeServicesInner {
    display: flex;
    justify-content: space-evenly;
    /*    margin: 20px;*/
}

.homeServicesPara p {
    margin: 30px;
}

#homeServicesImg {
    width: 20%;
    height: 20%;
}

.servicesBlock {
    display: flex;
    justify-content: space-evenly;
    /*    margin: 20px;*/
}

.serviceBlockElement {
    margin: 20px;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    color: white;
    background: #32370e;
    text-decoration: none;
    border-radius: 4px;
}

.homeBlogOuter {
    margin: 20px;
}

.homeBlog {
    display: flex;
    justify-content: space-evenly;
    /*    margin: 20px;*/
}

.homeBlog p {
    margin: 30px;
}

#homeBlogImg {
    width: 20%;
    height: 20%;
}

.homeFormsInner {
    display: flex;
    justify-content: space-evenly;
}

.homeFormOuter {
    margin: 20px;
}

.homeFormInner {
    margin: 20px;
    display: flex;
    justify-content: space-between;
}

.homeForm {
    display: flex;
    /*justify-content: space-evenly;*/
    /*    margin: 20px;*/
}

.homeForm button {
    color: white;
    background: #2c3e50;
}

.homeForm p {
    margin: 10px;
}

.homeFormContent {
    width: 50%;
}

.homeFormForm {
    width: 40%;
}

.homeFormForm input,
textarea {
    width: 80%;
    order: 1;
    /* display: block; */
    margin: 5px 0 5px;
    padding: 10px 6px;
    /*width: 100%;*/
    box-sizing: border-box;
    border: 0;
    border-bottom: 1px solid #ddd;
    color: #555;
}

.homeFormForm button {
    /*width: 20%;*/
    color: white;
    background: #2c3e50;
}

#homeFormsImg {
    width: 30%;
    height: 30%;
    /*margin: auto;*/
}
</style>
