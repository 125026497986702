<template>
    <div class="footer">
        <div>Olympia, WA</div>
        <div class="footer">
            <div id="FB"><a :href="fbLink"><img :src="fbIcon" /></a>&nbsp;&nbsp;&nbsp;</div>
            <div id="IG"><a :href="igLink"><img :src="igIcon" /></a>&nbsp;&nbsp;&nbsp;</div>
            <div id="linkedIn"><a :href="linkedInLink"><img :src="linkedInIcon" /></a>&nbsp;&nbsp;&nbsp;</div>
            <div id="YT"><a :href="ytLink"><img :src="ytIcon" /></a></div>
        </div>
        <div>&copy; {{ new Date().getFullYear() }} <a href="mailto:KHornberg@KimberlyHornberg.com">Kimbery Hornberg</a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fbIcon: "https://img.icons8.com/?size=25&id=84872&format=png&color=000000",
            fbLink: "https://www.facebook.com/kimberlyhornberg/",
            igIcon: "https://img.icons8.com/?size=25&id=84884&format=png&color=000000",
            igLink: "https://www.instagram.com/kimberly.hornberg",
            linkedInIcon: "https://img.icons8.com/?size=25&id=84888&format=png&color=000000",
            linkedInLink: "https://www.linkedin.com/in/kimberlycfoss/",
            ytIcon: "https://img.icons8.com/?size=25&id=84909&format=png&color=000000",
            ytLink: "https://www.youtube.com/channel/UCrdVqK52g1ZIJB31_2uFGdQ",
        }
    }
}
</script>

<style>
.footer {
    display: flex;
    /*flex;*/
    justify-content: space-between;
    /* space-between;*/
    align-items: center;
}
</style>