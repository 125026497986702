import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import BlogView from "../views/BlogView.vue";
import ServicesView from "../views/ServicesView.vue";
import NewsletterView from "../views/NewsletterView.vue";
import FullScriptView from "../views/FullScriptView.vue";
import NotFound404 from "../views/NotFound404.vue";


const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            display: "Home",
            title: "KimberlyHornberg.com",
        },
    },
    {
        path: "/about",
        name: "about",
        component: AboutView,
        meta: {
            display: "About",
            title: "KH - About",
        },
    },
    {
        path: "/services",
        name: "services",
        component: ServicesView,
        meta: {
            display: "Services",
            title: "KH - Services",
        },
    },
    {
        path: "/blog",
        name: "blog",
        //component: BlogView,
        meta: {
            display: "Blog",
            title: "KH - Blog",
        },
        beforeEnter() {location.href = 'https://substack.com/@kimberlyhornberg?utm_source=user-menu'}
    },
    {
        path: "/newsletter",
        name: "newsletter",
        component: NewsletterView,
        meta: {
            display: "Newsletter",
            title: "KH - Newsletter",
        },
    },
    {
        path: "/fullscript",
        name: "fullscript",
        component: FullScriptView,
        meta: {
            display: "FullScript",
            title: "KH - Fullscript",
        },
    },
    //catch all 404
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound404,
        meta: {
            hideNavbar: true,
        },
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'KimberlyHornberg.com'; // Set title based on route meta or default
    next();
  });

export default router;
